<template>
    <div class="box">
        <div
            class="box-toolbar"
            style="float:left;"
        >
            <router-link
                v-if="$can('admin/label/order-create')"
                :to="{ path: '/label/order/create?type=0' }"
            >
                <el-button
                    type="primary"
                    size="medium"
                >
                    新建标签
                </el-button>
            </router-link>

             <router-link
                v-if="$can('admin/label/order-create')"
                :to="{ path: '/label/order/create?type=1' }"
            >
                <el-button
                    type="primary"
                    size="medium"
                    style="margin-left:20px;"
                >
                    新建分类
                </el-button>
            </router-link>
        </div>
        <el-table
            border
            height="70vh"
            v-loading="loading"
            :stripe="true"
            :data="lists"
        >
            <el-table-column
                width="90"
                class-name="text-mono"
                prop="id"
                label="Id"
            >
            </el-table-column>

             <el-table-column
                prop="name"
                label="类型"
            > <template slot-scope="scope">
                {{scope.row.class?scope.row.class.name:scope.row.name +'【分类】'}}
                </template>

            </el-table-column>

            <el-table-column
                prop="name"
                label="名称"
            >
            </el-table-column>

            <el-table-column
                prop="parent_id"
                label="是否为分类"
            >
            <template slot-scope="scope">
                <el-button
                        plain
                        size='mini'
                        type="primary"
                        v-if="scope.row.parent_id == 0"
                    >是</el-button>
                    <el-button
                        plain
                        size='mini'
                        type="danger"
                        v-else
                    >否</el-button>

            </template>
            </el-table-column>

            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-dropdown trigger="click">
                        <el-button
                            plain
                            size="mini"
                            type="primary"
                        >
                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-if="$can('admin/label/order-update')"
                                @click.native="handleModelEdit(scope.row)"
                            >
                                编辑
                            </el-dropdown-item>
                            <el-dropdown-item
                                v-if="$can('admin/label/order-delete')"
                                @click.native="handleModelDelete(scope.row)"
                            >
                                删除
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <div class="bp-pages">
            <page
                :pageSize="limit"
                :count="count"
                @change="pageChange"
                @changeSize="changeSize"
            >
            </page>
        </div>
    </div>
</template>

<script>
import LabelService from '@admin/services/LabelService'
import pagination from '@admin/mixins/PaginationMixin'
import Page from '@admin/components/Pagination'
// import flatry from '@admin/utils/flatry'

export default {
  name: 'MoneyHome',
  components: { Page },
  mixins: [pagination],
  data () {
    return {
      loading: true,
      function: function (param) {
        return LabelService.order(param)
      },
      delete: function (param) {
        return LabelService.orderDel(param)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// .diao{
//   min-height: 600px;
//   height: 600px;
// }
</style>
